import env from './env'
import { Channel, ChannelDetails, SubChannel, SubChannelDetails } from './types'

// Defines whether the app runs locally (true) or is deployed to any env (false)
export const isDev = env.NODE_ENV === 'development'

// Defines the SSO app environment. Contains one of the following: dev, stage, prod
export const appEnvironment = env.RAZZLE_APP_ENVIRONMENT

// Defines whether ReCaptcha should be offered on frontend and validated on the backend
export const captchaEnabled = !isDev

export const UUID_SSO_APP = env.RAZZLE_UUID_SSO_APP
if (!UUID_SSO_APP) {
  throw new Error(`'UUID_SSO_APP' env var not provided.`)
}

export const LFC_TLD = env.RAZZLE_LFC_TLD
if (!LFC_TLD) {
  throw new Error('Missing env var: RAZZLE_LFC_TLD')
}

export const DATE_TIME_FORMAT_UI = 'dd/MM/yyyy'
export const DATE_TIME_FORMAT_ONETRUST = 'MM/dd/yyyy'
// Note: Cognito requires ISO datetime format

export const FALLBACK_DATE_ISO = '1900-01-01'

export const DEFAULT_DOB_ONETRUST = '01/01/1990' // with DATE_TIME_FORMAT_ONETRUST

export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

/**
 * This is a list of recognized channels that we can redirect back to and refer to by an enum
 */
const channelOrigin: ChannelDetails = {
  // Important this one comes before the web site, as it's a more specific match
  [Channel.TICKETING]: `https://www.${LFC_TLD}/tickets`,
  [Channel.WEBSITE]: `https://www.${LFC_TLD}`,
  [Channel.SHOP]: `https://store.${LFC_TLD}`,
  [Channel.TVGO]: `https://video.${LFC_TLD}`,
  [Channel.TVGO2]: `https://video2.${LFC_TLD}`,
  [Channel.TVGO3]: `https://video3.${LFC_TLD}`,
  [Channel.TVGOVIP]: `https://execgo.${LFC_TLD}`,
  [Channel.MATCHCENTRE]: `https://matchcentre.${LFC_TLD}`,
  [Channel.EACADEMY]: `https://eacademy.${LFC_TLD}`,
  [Channel.KOPRUN]: `https://koprun.${LFC_TLD}`,
  [Channel.REWARDS]: `https://rewards.${LFC_TLD}`,
  [Channel.REWARDS_LOYALTY]: `https://loyalty.${LFC_TLD}`,
  [Channel.REWARDS_DRUND]: `https://mylfc.${LFC_TLD}`,
  [Channel.GAMING]: `https://gaming.${LFC_TLD}`,
  [Channel.ANFIELDFOREVER]: `https://anfieldforever.${LFC_TLD}`,
  [Channel.MAGAZINE]: `https://emag.${LFC_TLD}`,
}

export const getOriginChannel = (
  originUrl: string,
  ecal: boolean,
): Channel | SubChannel | undefined => {
  if (ecal) {
    return SubChannel.ECAL
  }
  const [channel] =
    Object.entries(channelOrigin).find(([, channelUrl]) => originUrl.startsWith(channelUrl)) ?? []
  return channel as Channel | undefined
}

const channelRegistrationType: ChannelDetails = {
  [Channel.TICKETING]: 'SSO_TICKETING',
  [Channel.WEBSITE]: 'SSO_WEBSITE',
  [Channel.SHOP]: 'SSO_SHOP',
  [Channel.TVGO]: 'SSO_TVGO',
  [Channel.TVGO2]: 'SSO_TVGO',
  [Channel.TVGO3]: 'SSO_TVGO',
  [Channel.TVGOVIP]: 'SSO_TVGO_VIP',
  [Channel.MATCHCENTRE]: 'SSO_MATCH_CENTRE',
  [Channel.EACADEMY]: 'SSO_EACADEMY',
  [Channel.KOPRUN]: 'SSO_KOPRUN',
  [Channel.REWARDS]: 'SSO_REWARDS',
  [Channel.REWARDS_LOYALTY]: 'SSO_REWARDS_LOYALTY',
  [Channel.REWARDS_DRUND]: 'SSO_REWARDS_DRUND',
  [Channel.GAMING]: 'SSO_GAME',
  [Channel.ANFIELDFOREVER]: 'SSO_AF2',
  [Channel.MAGAZINE]: 'SSO_MAG',
}

const subChannelRegistrationType: SubChannelDetails = {
  [SubChannel.ECAL]: 'SSO_ECAL',
}

export const getRegistrationType = (originChannel: Channel | SubChannel | undefined): string =>
  originChannel ? { ...channelRegistrationType, ...subChannelRegistrationType }[originChannel] : ''

export const scrollToTop = () => {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  })
}

export interface SeatGeekAxiosResponse {
  success: boolean
  message?: string
  payload?: boolean
  error?: any
}
