import { Box, makeStyles } from '@material-ui/core'
import { Button } from './button'
import { Captcha } from './captcha'
import { Link } from './link'
import { Progress } from './progress'
import { useState } from 'react'
import { captchaEnabled } from '../../../shared/util'

type Props = {
  buttonProps: {
    disabled: boolean
    onClick: React.MouseEventHandler<HTMLButtonElement>
    text: string
  }
  linkProps?: {
    url: string
    text: string
  }
  altText?: string
  onCaptchaChange: (response: string | null) => void
  loading: boolean
}

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
})

export const ConfirmationButtons = function ({
  buttonProps,
  linkProps,
  altText,
  loading,
  onCaptchaChange,
}: Props): JSX.Element {
  const classes = useStyles()
  const [captchaOk, setCaptchaOk] = useState(!captchaEnabled)

  const handleCaptchaChange = (response: string | null) => {
    setCaptchaOk(response != null)
    onCaptchaChange(response)
  }

  return (
    <>
      <Box mb={2}>
        {captchaEnabled ? (
          <Captcha loading={loading} onChange={handleCaptchaChange} />
        ) : (
          <p>✅ Captcha is disabled for your local development pleasure</p>
        )}
      </Box>
      <Box mb={2} className={classes.wrapper}>
        <Button buttonProps={buttonProps} captchaOk={captchaOk} loading={loading} />
      </Box>
      {altText && <Link linkProps={linkProps} altText={altText} variant="body2" />}
      {loading && <Progress />}
    </>
  )
}
