/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import * as yup from 'yup'
import { useEffect, useState } from 'react'
import {
  codeSchema,
  emailSchema,
  membershipIdSchema,
  namePartSchema,
  phoneSchema,
} from '../shared/schemas'
import apiClient from '../lib/apiClient'
import { SeatGeekAxiosResponse } from '../shared/util'

export const useValidEmail = (initialValue: string) => {
  const [email, setEmail] = useState(initialValue)
  const [emailIsValid, setEmailIsValid] = useState(false)

  useEffect(() => {
    if (!email || email.length === 0) {
      setEmailIsValid(false)
      return
    }
    const isValid = emailSchema.isValidSync(email)
    setEmailIsValid(isValid)
  }, [email])

  useEffect(() => {
    setEmail(initialValue)
  }, [initialValue])

  return { email, setEmail, emailIsValid }
}
export const useValidTelephone = (initialValue: string) => {
  const [telephone, setTelephone] = useState(initialValue)
  const [telephoneIsValid, setTelephoneIsValid] = useState(false)

  useEffect(() => {
    if (!telephone || telephone.length === 0) {
      setTelephoneIsValid(true)
      return
    }
    const isValid = phoneSchema.isValidSync(telephone)
    setTelephoneIsValid(isValid)
  }, [telephone])

  useEffect(() => {
    setTelephone(initialValue)
  }, [initialValue])

  return { telephone, setTelephone, telephoneIsValid }
}

export const useValidNamePart = (initialValue: string) => {
  const [namePart, setNamePart] = useState(initialValue)
  const [namePartError, setNamePartError] = useState<string | null>(null)

  useEffect(() => {
    try {
      namePartSchema.validateSync(namePart)
      setNamePartError(null)
    } catch (err) {
      setNamePartError(err?.errors?.[0] ?? 'Invalid input')
    }
  }, [namePart])

  useEffect(() => {
    setNamePart(initialValue)
  }, [initialValue])

  return { namePart, setNamePart, namePartError, namePartIsValid: namePartError == null }
}

const passwordMatcher = (regex: RegExp) => {
  return yup.object().shape({
    password: yup.string().matches(regex).required(),
  })
}

const getPasswordErrors = (password: string, passwordConfirm: string) => {
  const isProperLength = passwordMatcher(/^.{8,30}$/).isValidSync({ password })
  const containsNumber = passwordMatcher(/\d/).isValidSync({ password })
  const containsUppercase = passwordMatcher(/[A-Z]/).isValidSync({ password })
  const containsLowercase = passwordMatcher(/[a-z]/).isValidSync({ password })
  const containsSpecialCharacter = passwordMatcher(
    /[\^$*.[\]{}()?\-+=\\"!@#%&\/,><':;|_~`]/,
  ).isValidSync({ password })
  const passwordsMatch = password === passwordConfirm

  return {
    isValid:
      isProperLength &&
      containsNumber &&
      containsUppercase &&
      containsLowercase &&
      containsSpecialCharacter &&
      passwordsMatch,
    passwordsMatch,
    errors: {
      isProperLength: !isProperLength ? 'Password has to be between 8 and 30 characters long' : '',
      containsNumber: !containsNumber ? 'Password has to contain at least one number' : '',
      containsUppercase: !containsUppercase
        ? 'Password has to contain at least one uppercase character'
        : '',
      containsLowercase: !containsLowercase
        ? 'Password has to contain at least one lowercase character'
        : '',
      containsSpecialCharacter: !containsSpecialCharacter
        ? 'Password has to contain at least one special chracter'
        : '',
      passwordsMatch: !passwordsMatch ? 'Password and password confirmation have to match' : '',
    },
  }
}

export const useValidPassword = (initialValue: string, confirmRequired?: boolean) => {
  const [password, setPassword] = useState(initialValue)
  const [passwordConfirm, setPasswordConfirm] = useState(initialValue)

  const [passwordIsValid, setPasswordIsValid] = useState(true)
  const [passwordConfirmIsValid, setPasswordConfirmIsValid] = useState(true)

  const [passwordError, setPasswordError] = useState<string[]>([])

  useEffect(() => {
    if (password.length === 0) {
      setPasswordIsValid(true)
      return
    }

    const passwordErrors = getPasswordErrors(password, passwordConfirm)
    setPasswordIsValid(passwordErrors.isValid)

    setPasswordError(Object.values(passwordErrors.errors))
    setPasswordConfirmIsValid(passwordErrors.passwordsMatch)
  }, [confirmRequired, password, passwordConfirm, passwordIsValid])

  return {
    password,
    passwordConfirm,
    setPassword,
    setPasswordConfirm,
    passwordIsValid,
    passwordConfirmIsValid,
    passwordError,
  }
}

export const useValidCode = (initialValue: string) => {
  const [code, setCode] = useState(initialValue)
  const [codeIsValid, setCodeIsValid] = useState(true)

  useEffect(() => {
    if (code.length === 0) {
      setCodeIsValid(true)
      return
    }

    const isValid = codeSchema.isValidSync({ code })

    setCodeIsValid(isValid)
  }, [code])

  return { code, setCode, codeIsValid }
}

// export const useValidMembershipId = (
//   initialValue: string,
//   firstName: string,
//   lastName: string,
//   dob: Date | null,
// ) => {
//   const [membershipId, setMembershipId] = useState(initialValue)
//   const [membershipIsValid, setMembershipIsValid] = useState(false)
//   const [isMembershipRequestLoading, setMembershipRequestLoading] = useState(false)

//   useEffect(() => {
//     const validateMembership = async () => {
//       if (!membershipId || membershipId.length === 0) {
//         setMembershipIsValid(true)
//         return
//       }

//       /*
//       MembershipId field pattern validation
//       - Only numerics
//       - Minimum length of 7 (API trigger starts with 7 chars)
//       - Max length of 14 (API trigger ends with 14 chars)
//       */

//       const isValid = membershipIdSchema.isValidSync(membershipId)
//       if (!isValid) {
//         setMembershipIsValid(isValid)
//         return
//       }
//       if (isValid) {
//         setMembershipRequestLoading(true)
//         const response = await apiClient.post<SeatGeekAxiosResponse>('/validate_user_membership', {
//           membershipId,
//           firstName,
//           lastName,
//           dateOfBirth: dob,
//         })
//         setMembershipRequestLoading(false)
//         // user credentials (firstname, lastname, dob) are validated against the seatgeek response data for the membership id.
//         // The validated result (true or false) is returned in the response payload.
//         response.data.payload ? setMembershipIsValid(true) : setMembershipIsValid(false)
//       }
//     }
//     validateMembership()
//   }, [membershipId, firstName, lastName, dob])

//   useEffect(() => {
//     setMembershipId(initialValue)
//   }, [initialValue])

//   return { membershipId, setMembershipId, membershipIsValid, isMembershipRequestLoading }
// }
