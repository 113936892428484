import { Box, Grid, Link, makeStyles, CircularProgress } from '@material-ui/core'
import {
  ConfirmationButtons,
  ContentWrapper,
  DateOfBirth,
  Layout,
  MarketingConsent,
  RequiredField,
  Select,
  TextField,
} from '../components'
import { DataElements, DataSubjectPreferences } from '../shared/types'
import { captchaEnabled } from '../shared/util'
import { getCountries, getGenders, getUsStates } from '../lib/data'
import { useCallback, useState } from 'react'

import { DATE_TIME_FORMAT_ONETRUST } from '../shared/util'
import { scrollToTop } from '../shared/util'
import { Email } from '../components'
import { format } from 'date-fns'
import {
  checkIfNickNameExists,
  saveNickNameToForumDynamoDB,
  setUserPreferences,
} from '../context/dispatchers'
import { useAuth } from '../context/authContext'
import { useHistory, useLocation } from 'react-router-dom'
import { useUserPreferences } from '../hooks/useUserPreferences'
import { useValidEmail, useValidNamePart, useValidTelephone } from '../hooks/useValidInputs'
import { SupporterInfo } from '../components/layout-components/content-wrapper/header/supporterInfo'
import { useGTMEvent } from '../hooks/useGTMEvent'

const useStyles = makeStyles(function (theme) {
  return {
    well: {
      marginTop: -20,
    },
    buttonsWrapper: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(8),
      },
    },
    requiredFieldAsterisk: {
      color: theme.palette.primary.main,
    },
  }
})

const Profile = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const {
    state: { userPreferences },
    dispatch,
  } = useAuth()

  useGTMEvent('LFC SSO Edit Profile')

  const {
    firstName: initFirstName,
    lastName: initLastName,
    nickname,
    email: initEmail,
    telephone: initTelephone,
    dob,
    gender,
    country,
    state,
    consent,
    metaElements,
    setNickname,
    setDob,
    setGender,
    setCountry,
    setState,
    setConsent,
  } = useUserPreferences(userPreferences)

  const { email, emailIsValid, setEmail } = useValidEmail(initEmail || '')
  const {
    namePart: firstName,
    namePartError: firstNameError,
    namePartIsValid: firstNameIsValid,
    setNamePart: setFirstName,
  } = useValidNamePart(initFirstName || '')
  const {
    namePart: lastName,
    namePartError: lastNameError,
    namePartIsValid: lastNameIsValid,
    setNamePart: setLastName,
  } = useValidNamePart(initLastName || '')
  const { telephone, telephoneIsValid, setTelephone } = useValidTelephone(initTelephone || '')
  const [nickNameIsValid, setNickNameIsValid] = useState<boolean>(true)

  const inValidAttributes = !(
    firstNameIsValid &&
    lastNameIsValid &&
    telephoneIsValid &&
    nickNameIsValid &&
    country
  )

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null)

  const countries = getCountries()
  const states = getUsStates()
  const genders = getGenders()

  const checkIfNickNameAvailable = async () => {
    const result = await checkIfNickNameExists(nickname)
    return result
  }

  const saveNickName = async (
    nickname: string,
    data: { success?: boolean; sessionId?: string },
  ) => {
    if (data.success && data.sessionId) {
      const response = await saveNickNameToForumDynamoDB({ nickname, sessionId: data.sessionId })
      return response
    }
  }

  const saveChanges = useCallback(async () => {
    setLoading(true)

    if (dob === null) {
      setError('Please enter your Birth date')
      setLoading(false)
      return
    }
    if (captchaEnabled && captchaResponse === null) {
      setError('Please complete the Captcha')
      setLoading(false)
      return
    }
    const dataElements: DataElements = {
      'First Name': firstName,
      'Last Name': lastName,
      Nickname: nickname ?? '',
      'Date of Birth': format(dob, DATE_TIME_FORMAT_ONETRUST),
      'Email Address': email,
      Telephone: telephone ?? '',
      'LFC Country': country ?? '',
      'LFC State': state ?? '',
      Gender: gender ?? '',
    }
    const newUserPreferences: DataSubjectPreferences = {
      consent,
      dataElements,
      metaElements,
    }

    try {
      /* --- Update Nickname to Forum DynamoDB --- */
      if (nickname && userPreferences?.dataElements.Nickname !== nickname) {
        setNickNameIsValid(true)

        const result = await checkIfNickNameAvailable()
        if (!result.success) {
          setNickNameIsValid(false)
          setLoading(false)
          scrollToTop()
          return
        }

        const res = await saveNickName(nickname, result)
        if (res && !res.success) {
          setNickNameIsValid(false)
          setLoading(false)
          scrollToTop()
          return
        }
      }

      await setUserPreferences(dispatch, newUserPreferences, captchaResponse)

      setSuccess(true)
      // JSBridge POQ profile update event trigger
      // window.PoqWebCheckout.options({ debug: true })
      window.PoqWebCheckout.send('editcomplete')
      scrollToTop()
    } catch (err) {
      setError(err.message)
      scrollToTop()
    }

    setLoading(false)
  }, [
    firstName,
    lastName,
    nickname,
    email,
    telephone,
    // membershipId,
    dob,
    country,
    state,
    gender,
    consent,
    metaElements,
    dispatch,
    captchaResponse,
  ])

  const updatePassword = useCallback(
    function () {
      history.push(`/update-password${location.search}`)
    },
    [history, location],
  )

  const handleNickNameOnBlur = (value: string) => {
    setNickname(value.trim())
  }

  const handleNickNameOnChange = (value: string) => {
    setNickNameIsValid(true)
    setNickname(value)
  }

  return (
    <Layout>
      <ContentWrapper
        columns={2}
        headerTitle="Edit Your Profile"
        headerChildren={
          <>
            <div>
              <SupporterInfo
                firstName={firstName}
                lastName={lastName}
                supporterID={metaElements?.SupporterID}
              />
              To update your password, please visit the{' '}
              <Link href="#" onClick={updatePassword} color="primary">
                Update Your Password
              </Link>{' '}
              page
            </div>
          </>
        }
        error={error}
        success={
          success
            ? {
                title: 'Your profile has been updated',
              }
            : undefined
        }
        fullWidth={true}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <RequiredField
              label="First name"
              setValue={setFirstName}
              value={firstName}
              message={firstNameError}
              inputLabelClasses={classes.requiredFieldAsterisk}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RequiredField
              label="Surname"
              setValue={setLastName}
              value={lastName}
              message={lastNameError}
              inputLabelClasses={classes.requiredFieldAsterisk}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Email
              defaultValue={email}
              emailIsValid={emailIsValid}
              setEmail={setEmail}
              classes={classes.requiredFieldAsterisk}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Nickname"
              setValue={setNickname}
              value={nickname}
              error={!nickNameIsValid}
              helperText={!nickNameIsValid ? `Nickname unavailable. Try a different name` : ''}
              InputLabelProps={{
                shrink: true,
              }}
              handleOnChange={handleNickNameOnChange}
              handleOnBlur={handleNickNameOnBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateOfBirth value={dob} onChange={setDob} classes={classes.requiredFieldAsterisk} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              values={genders}
              setValue={setGender}
              label="Gender"
              name="gender"
              code={gender}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile"
              setValue={setTelephone}
              value={telephone}
              error={!telephoneIsValid}
              helperText={telephoneIsValid ? '' : 'Invalid phone number'}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Membership Id"
              setValue={setMembershipId}
              value={membershipId}
              error={!membershipIsValid}
              helperText={membershipIsValid ? '' : 'Invalid membership id'}
              InputProps={
                !membershipId
                  ? {}
                  : {
                      endAdornment: isMembershipRequestLoading ? (
                        <CircularProgress size={20} thickness={3} />
                      ) : (
                        <ValidityIcon valid={membershipIsValid} />
                      ),
                    }
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <Select
              required
              values={countries}
              setValue={setCountry}
              label="Country"
              name="country"
              code={country}
              classes={classes.requiredFieldAsterisk}
            />
          </Grid>
          {country === 'US' && (
            <Grid item xs={12} sm={6}>
              <Select
                required
                values={states}
                setValue={setState}
                label="State"
                name="state"
                code={state}
                classes={classes.requiredFieldAsterisk}
              />
            </Grid>
          )}
          <MarketingConsent consent={consent} setConsent={setConsent} showIntro={false} />
        </Grid>

        <Box width={304} mx="auto" className={classes.buttonsWrapper}>
          <ConfirmationButtons
            onCaptchaChange={setCaptchaResponse}
            buttonProps={{
              onClick: saveChanges,
              disabled: inValidAttributes,
              text: 'Update now',
            }}
            loading={loading}
          />
        </Box>
      </ContentWrapper>
    </Layout>
  )
}
export default Profile
