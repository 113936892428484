import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useAuth } from '../context/authContext'
import { useHistory, useLocation } from 'react-router-dom'
import { Channel, ChannelDetails, SubChannel, SubChannelDetails } from '../shared/types'
import { LFC_TLD } from '../shared/util'

const channelLabel: ChannelDetails & SubChannelDetails = {
  [Channel.TICKETING]: 'Ticketing',
  [Channel.WEBSITE]: 'Web Site',
  [Channel.SHOP]: 'Shop',
  [Channel.TVGO]: 'LFCTV GO',
  [Channel.TVGO2]: 'LFCTV GO',
  [Channel.TVGO3]: 'LFCTV GO',
  [Channel.TVGOVIP]: 'LFCTV GO',
  [Channel.MATCHCENTRE]: 'Match Centre',
  [Channel.EACADEMY]: 'E Academy',
  [Channel.KOPRUN]: 'Kop Run',
  [Channel.REWARDS]: 'Rewards',
  [Channel.GAMING]: 'Gaming',
  [Channel.MAGAZINE]: 'E-Magazine',
  [Channel.ANFIELDFOREVER]: 'Anfield Forever',
  [Channel.REWARDS_LOYALTY]: 'Rewards',
  [Channel.REWARDS_DRUND]: 'MyLFC',
  [SubChannel.ECAL]: 'Web Site',
}

export const useRedirect = (
  /**
   * A relative URL to redirect to (like /login), or omit to redirect back to
   * the origin URL (inferred from querystring)
   */
  url: string | undefined,
  autoTrigger = true,
  label?: string,
): [() => void, string | undefined] => {
  const history = useHistory()
  const location = useLocation()
  const {
    state: { originUrl, originChannel },
  } = useAuth()

  const timeoutId = useRef(0)

  const handleRedirect = useCallback(() => {
    // (always) Clear the timeout, in the case the user explicitly clicked on the `Continue` button.
    // Otherwise this will execute again
    window.clearTimeout(timeoutId.current)
    if (url !== undefined) {
      history.push(`${url}${location.search}`)
    } else if (originUrl === window.location.origin) {
      // history.push('/')
      // Default redirect - liverpoolfc.com (Home)
      // window.location.href = `https://www.${LFC_TLD}`
      // SSO-464
      // If user registered without a product journey they should be redirected to myLFC site
      window.location.href = `https://mylfc.${LFC_TLD}`
    } else {
      window.location.href = `${originUrl}`
    }
  }, [history, location, url, originUrl, timeoutId])

  const redirectLabel = useMemo(
    () => label ?? (originChannel ? channelLabel[originChannel] : ''),
    [label, originChannel],
  )

  useEffect(() => {
    if (autoTrigger) {
      timeoutId.current = window.setTimeout(() => {
        handleRedirect()
      }, 5000)
    }
  }, [handleRedirect, autoTrigger])

  return [handleRedirect, redirectLabel]
}
