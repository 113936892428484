import env from './env'

if (
  !env.RAZZLE_ONETRUST_PURPOSE_ID_EMAIL ||
  !env.RAZZLE_ONETRUST_PURPOSE_ID_SMS ||
  !env.RAZZLE_ONETRUST_PURPOSE_ID_MAIL ||
  !env.RAZZLE_ONETRUST_PURPOSE_ID_TELEPHONE ||
  !env.RAZZLE_ONETRUST_PURPOSE_ID_PRODUCTS ||
  !env.RAZZLE_ONETRUST_PURPOSE_ID_NEWS ||
  !env.RAZZLE_ONETRUST_PURPOSE_ID_FOUNDATION ||
  !env.RAZZLE_ONETRUST_PURPOSE_ID_PARTNERS ||
  !env.RAZZLE_ONETRUST_PURPOSE_ID_LEGITIMATE_INTEREST
) {
  throw new Error('One or more OneTrust Purpose ID env vars is missing.')
}

export interface PurposeData {
  readonly id: string

  readonly label?: string
  readonly description?: string
}

export interface CollectionPointData {
  readonly purposes: PurposeData[]
  readonly dataElements?: string[]
}

export type Consent = (PurposeData & { given: boolean })[]

export interface DataElements {
  Nickname?: string
  'First Name'?: string
  'Last Name'?: string
  'Date of Birth'?: string
  'Email Address'?: string
  'LFC Country'?: string
  'LFC State'?: string
  Gender?: string
  Telephone?: string
}

export interface MetaElements {
  // Stored as `membershipId` in Cognito
  readonly SupporterID?: string
  readonly RegistrationType?: string
}

export interface DataSubjectPreferences {
  readonly dataElements: DataElements
  readonly metaElements?: MetaElements
  readonly consent: Consent
  readonly language?: string
}

export enum Channel {
  TICKETING = 'TICKETING',
  WEBSITE = 'WEBSITE',
  SHOP = 'SHOP',
  TVGO = 'TVGO',
  TVGO2 = 'TVGO2',
  TVGO3 = 'TVGO3',
  TVGOVIP = 'TVGOVIP',
  MATCHCENTRE = 'MATCHCENTRE',
  EACADEMY = 'EACADEMY',
  KOPRUN = 'KOPRUN',
  REWARDS = 'REWARDS',
  REWARDS_LOYALTY = 'REWARDS_LOYALTY',
  REWARDS_DRUND = 'REWARDS_DRUND',
  GAMING = 'GAMING',
  ANFIELDFOREVER = 'ANFIELDFOREVER',
  MAGAZINE = 'MAGAZINE',
}

export enum SubChannel {
  ECAL = 'ECAL',
}

export type ChannelDetails = {
  [key in keyof typeof Channel]: string
}

export type SubChannelDetails = {
  [key in keyof typeof SubChannel]: string
}

export const OneTrustPurposeId = {
  Email: env.RAZZLE_ONETRUST_PURPOSE_ID_EMAIL,
  SMS: env.RAZZLE_ONETRUST_PURPOSE_ID_SMS,
  Mail: env.RAZZLE_ONETRUST_PURPOSE_ID_MAIL,
  Telephone: env.RAZZLE_ONETRUST_PURPOSE_ID_TELEPHONE,
  Products: env.RAZZLE_ONETRUST_PURPOSE_ID_PRODUCTS,
  News: env.RAZZLE_ONETRUST_PURPOSE_ID_NEWS,
  Foundation: env.RAZZLE_ONETRUST_PURPOSE_ID_FOUNDATION,
  Partners: env.RAZZLE_ONETRUST_PURPOSE_ID_PARTNERS,
  LegitimateInterest: env.RAZZLE_ONETRUST_PURPOSE_ID_LEGITIMATE_INTEREST,
}

export type MembershipUserRecord = {
  membershipId: string
  firstName: string
  lastName: string
  dateOfBirth: string
}
