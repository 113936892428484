import { Box, Grid, makeStyles } from '@material-ui/core'
import {
  ConfirmationButtons,
  ContentWrapper,
  DateOfBirth,
  Layout,
  List,
  MarketingConsent,
  RequiredField,
  Well,
} from '../components'
import { Email, Password, Select } from '../components'
import { Link as FormLink } from '../components/form-components/confirmation-buttons/link'
import { getCountries, getGenders, getUsStates } from '../lib/data'
import { signUp } from '../context/dispatchers'
import { useCallback, useEffect, useState } from 'react'
import { useValidEmail, useValidNamePart, useValidPassword } from '../hooks/useValidInputs'

import { DATE_TIME_FORMAT_ONETRUST, getRegistrationType, scrollToTop } from '../shared/util'
import { Consent, DataSubjectPreferences } from '../shared/types'
import { format } from 'date-fns'
import { useAuth } from '../context/authContext'
import { useOneTrust } from '../hooks/useOneTrust'
import { useUserPreferences } from '../hooks/useUserPreferences'
import { useLocation } from 'react-router-dom'
import { useGTMEvent } from '../hooks/useGTMEvent'

const useStyles = makeStyles(function (theme) {
  return {
    well: {
      marginTop: -20,
      fontSize: '10px',
    },
    buttonsWrapper: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(8),
      },
    },
    requiredFieldAsterisk: {
      color: theme.palette.primary.main,
    },
  }
})

const Register = (): JSX.Element => {
  const classes = useStyles()
  const location = useLocation()

  const {
    state: { email: initEmail, originChannel },
    dispatch,
  } = useAuth()

  const {
    firstName: initFirstName,
    lastName: initLastName,
    dob,
    gender,
    country,
    state,
    setDob,
    setGender,
    setCountry,
    setState,
  } = useUserPreferences()

  const { email, setEmail, emailIsValid } = useValidEmail(initEmail)
  const {
    namePart: firstName,
    namePartError: firstNameError,
    namePartIsValid: firstNameIsValid,
    setNamePart: setFirstName,
  } = useValidNamePart(initFirstName || '')
  const {
    namePart: lastName,
    namePartError: lastNameError,
    namePartIsValid: lastNameIsValid,
    setNamePart: setLastName,
  } = useValidNamePart(initLastName || '')
  const {
    password,
    passwordConfirm,
    setPassword,
    setPasswordConfirm,
    passwordIsValid,
    passwordConfirmIsValid,
    passwordError,
  } = useValidPassword('', true)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null)
  const [consentInteractionRequired, setConsentInteractionRequired] = useState(true)
  const { consent, setConsent: rawSetConsent } = useOneTrust('Registration')

  const setConsent = (value: Consent) => {
    if (consentInteractionRequired) {
      setConsentInteractionRequired(false)
    }
    rawSetConsent(value)
  }

  const pushGTMEvent = useGTMEvent('LFC SSO Registration')
  useEffect(
    () =>
      pushGTMEvent({
        event: 'openedregistration',
        eventCategory: 'Account',
        eventAction: 'Registration',
        eventLabel: 'Starts',
      }),
    [location.pathname],
  )

  const isInvalid =
    !firstNameIsValid ||
    !lastNameIsValid ||
    !email ||
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0 ||
    consentInteractionRequired

  const signUpEvent = useCallback(async () => {
    setLoading(true)

    try {
      if (dob === null || !email) return
      const userPreferences: DataSubjectPreferences = {
        consent,
        dataElements: {
          'First Name': firstName,
          'Last Name': lastName,
          'Date of Birth': format(dob, DATE_TIME_FORMAT_ONETRUST),
          'Email Address': email,
          'LFC Country': !country ? 'GB' : country,
          'LFC State': state,
          Gender: gender,
          Nickname: '',
          Telephone: '',
        },
        metaElements: {
          RegistrationType: getRegistrationType(originChannel),
        },
      }
      await signUp(dispatch, email, password, userPreferences, captchaResponse)
      pushGTMEvent({
        event: 'completedregistation',
        eventCategory: 'Account',
        eventAction: 'Registration',
        eventLabel: 'Completion',
      })
      // await signIn(dispatch, email, password, true)

      // JSBridge POQ registration success event trigger
      // window.PoqWebCheckout.options({ debug: true })
      window.PoqWebCheckout.send('registrationcomplete', {
        customData: {
          email: `${email}`,
        },
      })

      // Native events: urbanZoo native regiration event triggers for android and iOS
      if (typeof window.android !== 'undefined') {
        window.android.onRegistrationSuccess()
      }
      if (typeof window.webkit !== 'undefined') {
        window.webkit.messageHandlers.iOS.postMessage('RegistrationComplete')
      }
    } catch (err) {
      setError(err.message)
      pushGTMEvent({
        event: 'failedregistration',
        eventCategory: 'Account',
        eventAction: 'Registration',
        eventLabel: 'Failed',
      })
      scrollToTop()
    }

    setLoading(false)
  }, [
    dob,
    consent,
    firstName,
    lastName,
    email,
    country,
    state,
    gender,
    dispatch,
    password,
    captchaResponse,
    pushGTMEvent,
    originChannel,
  ])

  const countries = getCountries()
  const states = getUsStates()
  const genders = getGenders()

  return (
    <Layout>
      <ContentWrapper
        columns={2}
        headerTitle="Why join MyLFC?"
        headerChildren={
          <>
            Unlock new, exciting and exclusive benefits all in one place.
            <br /> Join MyLFC, your FREE,Official LFC Account.
            <ul style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <li>The chance to win amazing prizes every week</li>
              <li>Unlock behind-the-scenes videos</li>
              <li>Follow the match with live commentary </li>
              <li>Keep up to date through the Club newsletter</li>
              <li>Read exclusive interviews in the eMagazine</li>
              <li>Play EA Sports FIFA 23 at LFC Gaming</li>
              <li>Join the conversation on LFC Fan Forums</li>
              <li>Earn even more benefits and rewards</li>
            </ul>
            <FormLink
              linkProps={{ url: '/login', text: 'Log In' }}
              altText="Already have an account?"
            />
          </>
        }
        error={error}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <RequiredField
              label="First name"
              setValue={setFirstName}
              value={firstName}
              message={firstName === '' ? null : firstNameError}
              inputLabelClasses={classes.requiredFieldAsterisk}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RequiredField
              label="Surname"
              setValue={setLastName}
              value={lastName}
              message={lastName === '' ? null : lastNameError}
              inputLabelClasses={classes.requiredFieldAsterisk}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Email
              defaultValue={email}
              emailIsValid={emailIsValid}
              setEmail={setEmail}
              classes={classes.requiredFieldAsterisk}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateOfBirth value={dob} onChange={setDob} classes={classes.requiredFieldAsterisk} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Password
              label="Password"
              passwordIsValid={passwordIsValid}
              setPassword={setPassword}
              error={passwordError}
              isNew
              classes={classes.requiredFieldAsterisk}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Password
              label="Re-enter Password"
              passwordIsValid={passwordConfirmIsValid}
              setPassword={setPasswordConfirm}
              isNew
              classes={classes.requiredFieldAsterisk}
            />
          </Grid>
          <Grid item xs={12}>
            <Well className={classes.well}>
              <List title="For your security please ensure your password:">
                <>Is between 8 and 30 characters long</>
                <>Contains a mix of upper (capital) and lower case letters</>
                <>Contains at least one number</>
                <>
                  Contains at least one special character{' '}
                  {'(* : @ | # % , . ; [ ] { } ? - _ ~ $ & < > ‘ “ ^ ( ) / ! `)'}
                </>
              </List>
            </Well>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              values={genders}
              setValue={setGender}
              label="Gender"
              name="gender"
              code={gender}
              defaultValue={genders.find((g) => g.code === '')?.code}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              required
              values={countries}
              setValue={setCountry}
              label="Country"
              name="country"
              code={country}
              defaultValue={countries.find((c) => c.code === 'GB')?.code}
              classes={classes.requiredFieldAsterisk}
            />
          </Grid>
          {country === 'US' && (
            <Grid item xs={12} sm={6}>
              <Select
                required
                values={states}
                setValue={setState}
                label="State"
                name="state"
                code={state}
                classes={classes.requiredFieldAsterisk}
              />
            </Grid>
          )}
          <MarketingConsent
            consent={consent}
            setConsent={setConsent}
            interactionRequired={consentInteractionRequired}
          />
        </Grid>

        <Box width={304} mx="auto" className={classes.buttonsWrapper}>
          <ConfirmationButtons
            onCaptchaChange={setCaptchaResponse}
            buttonProps={{
              onClick: signUpEvent,
              disabled: isInvalid,
              text: 'Register Now',
            }}
            linkProps={{
              url: '/login',
              text: 'Log In',
            }}
            altText="Already have an account?"
            loading={loading}
          />
        </Box>
      </ContentWrapper>
    </Layout>
  )
}
export default Register
