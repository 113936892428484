declare global {
  interface Window {
    env: { [key: string]: string }
    PoqWebCheckout: any
    android: any
    webkit: any
  }
}

// Here you explicitly include the env vars that should be exposed for the front-end.
export default typeof window !== 'undefined'
  ? { ...(window.env || {}) }
  : {
      NODE_ENV: process.env.NODE_ENV,
      PORT: process.env.PORT,
      RAZZLE_NAME: process.env.RAZZLE_NAME,
      RAZZLE_UUID_SSO_APP: process.env.RAZZLE_UUID_SSO_APP,
      RAZZLE_LFC_TLD: process.env.RAZZLE_LFC_TLD,
      RAZZLE_ONETRUST_PURPOSE_ID_EMAIL: process.env.RAZZLE_ONETRUST_PURPOSE_ID_EMAIL,
      RAZZLE_ONETRUST_PURPOSE_ID_SMS: process.env.RAZZLE_ONETRUST_PURPOSE_ID_SMS,
      RAZZLE_ONETRUST_PURPOSE_ID_MAIL: process.env.RAZZLE_ONETRUST_PURPOSE_ID_MAIL,
      RAZZLE_ONETRUST_PURPOSE_ID_TELEPHONE: process.env.RAZZLE_ONETRUST_PURPOSE_ID_TELEPHONE,
      RAZZLE_ONETRUST_PURPOSE_ID_PRODUCTS: process.env.RAZZLE_ONETRUST_PURPOSE_ID_PRODUCTS,
      RAZZLE_ONETRUST_PURPOSE_ID_NEWS: process.env.RAZZLE_ONETRUST_PURPOSE_ID_NEWS,
      RAZZLE_ONETRUST_PURPOSE_ID_FOUNDATION: process.env.RAZZLE_ONETRUST_PURPOSE_ID_FOUNDATION,
      RAZZLE_ONETRUST_PURPOSE_ID_PARTNERS: process.env.RAZZLE_ONETRUST_PURPOSE_ID_PARTNERS,
      RAZZLE_ONETRUST_PURPOSE_ID_LEGITIMATE_INTEREST:
        process.env.RAZZLE_ONETRUST_PURPOSE_ID_LEGITIMATE_INTEREST,
      RAZZLE_RECAPTCHA_SITEKEY: process.env.RAZZLE_RECAPTCHA_SITEKEY,
      RAZZLE_APP_ENVIRONMENT: process.env.RAZZLE_APP_ENVIRONMENT,
    }
